<template>
  <div>
    <transition name="fade">
      <div
          v-if="showing"
          class="inset-0 z-40 absolute bg-black bg-opacity-50"
      ></div>
    </transition>
    <transition name="slide-bottom">
      <div
          v-if="showing"
          class="absolute inset-0 z-40 flex items-center justify-center p-24"
      >
        <div
            class="w-full bg-white h-full shadow-2xl z-40 px-10 pt-10 flex flex-col rounded overflow-hidden"
        >
          <div class="w-full flex items-center pb-6">
            <div>
              <h3
                  class="uppercase text-lg font-semibold text-primary tracking-wide"
              >
                {{ documentName }}
              </h3>
            </div>
            <hr class="flex-1 border-primary ml-4 mr-10"/>
            <button
                @click.prevent="close"
                class="flex items-center bg-white text-secondary p-2 font-semibold rounded border border-secondary hover:bg-gray-200 hover:shadow active:shadow-inner transition duration-100"
            >
              <svg
                  class="stroke-current h-6 w-6"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"/>
              </svg>
            </button>
          </div>
          <div
              class="flex-1 flex flex-col max-h-full -mx-10 relative bg-gray-300"
          >
            <iframe
                v-if="pdfUrl"
                class="h-full w-full"
                :src="pdfUrl"
                allowfullscreen
                webkitallowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "PDFViewer",
  props: {
    showing: {
      type: Boolean,
      required: false
    },
    pdfBlob: {
      type: [Blob, String, null],
      default: null
    },
    documentName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      pdfUrl: null
    };
  },
  watch: {
    pdfBlob: {
      immediate: true,
      handler(newBlob) {
        let pdfUrl = "";
        if (newBlob instanceof Blob) {
          pdfUrl = URL.createObjectURL(newBlob);
        } else {
          pdfUrl = newBlob;
        }
        this.pdfUrl = pdfUrl;

      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
      if (this.pdfUrl && (typeof this.pdfUrl === 'string' && this.pdfUrl.startsWith('blob'))) {
        URL.revokeObjectURL(this.pdfUrl);
      }
      this.pdfUrl = null;
    }
  }
};
</script>
